import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import { helpers } from "@cargo/common";
import { ResetButton } from "@cargo/ui-kit";
import { FRONTEND_DATA } from "../../globals";
import { globalUndoManager } from "../../lib/undo-redo";

const resizeMap = new Map();

const resizeObserver = new ResizeObserver(function(entries){
	entries.forEach(function(entry){

		const component = resizeMap.get(entry.target);

		if( component){
			let box = entry.borderBoxSize[0] || entry.borderBoxSize;			
			component.setState({
				textInputSize: box.inlineSize
			});
		}
	});
});


const unitIncrements = {
	'noUnit': 1,
	'etc': 1,

	'rem': .1,
	'ex': .1,
	'ch': .1,
	'em': .1,
	'vw': .1,
	'vh': .1,
	'vi': .1,
	'vb': .1,
	'vmin': .1,
	'vmax': .1,
	'%': .1,
	'cm': .1,
	'mm': 1,
	'Q': 1,
	'in': .1,
	'pc': .1,
	'pt': .1,
	'px': 1,
}

class Scrubber extends Component {
	constructor(props){
		super(props);

		this.validatorTest = document.createElement('div');

		let inputValue = this.processInput(props.field.value);

		this.state = {

			minimumDecimalPlaces: 0,
			updateFieldAfterInput: false,
			
			scrubProgressAtRulerStop: 0,
			rulerAtMax: false,
			rulerAtMin: false,
			rulerWidth: 100,
			rulerTickWidth: 10,
			pointerDownPosition: 0,

			fastMode: true,
			textInputSize: 30,

			displayValue: inputValue,
			lastValidValue: inputValue.isValid ? _.cloneDeep(inputValue) :  null,
			incrementing: false,
			hasFocus: false,
			dragging: false,
			wheeling: false,

			pointerMaskIndex: -1,
			activeCursorRange: -1,
			activeCursorIndex: -1,
			pointerDown: false,

			scrubProgress: 0, // slight offset for initial position

			scrubberValueWasHidden: false

		}

		this.scrubberRef = React.createRef();
		this.scrubberInputRef = React.createRef();
		this.clickMaskRef = React.createRef();
		this.rulerRef = React.createRef();
		this.debouncedSelectInInput = _.debounce(this.selectInInput, 0);

		// used in calculating position of 'ruler' graphic
		this.allDelta = 0;

		this.accumulatedDelta = 0;
		this.dragDelta = 0;

	}

	render(){
		
		let {
			field,
			className,
			innerRef,
			form,
			label,
			step,
			numberOnlyMode,
			disabled,
			suffix,
			id="scrubber/"+this.props.field.name,
			hideScrubberValue,
			isOverriding,
			overrideReset
		} = this.props;

		let {
			displayValue,
			lastValidValue,
			dragging,
			hasFocus,
			incrementing,
			wheeling,
			textInputSize,
			scrubProgress,
			fastMode,
			rulerWidth,
			rulerTickWidth,
			pointerDownPosition,
		} = this.state;

		const {
			isValid,
			valueArray,
			calculatedValue,
		} = (displayValue || {isValid: false, valueArray: [], calculatedValue: ''} );

		let scrubProgressZoomOffset = 0;
		let magnification = (rulerTickWidth - 10) / (20 - 10)
		let moduloScrubProgress =(scrubProgress)%(rulerWidth);

		scrubProgressZoomOffset =  (pointerDownPosition+moduloScrubProgress) * -magnification  + magnification * (moduloScrubProgress*.5)

		let compiledClassNames = `scrubber`+ 
			`${className ? ` `+className : ''}`+
			`${disabled ? ' disabled' : ''}`+
			`${fastMode ? ' fast-mode' : ''}`+
			`${isValid ? '': ' invalid'}`+
			`${wheeling || dragging || !fastMode ? ' scrubbing': ''}`+
			`${hideScrubberValue ? ' hide-scrubber-value' : ''}`+
			`${isOverriding && overrideReset ? ' overridden' : ''}`;

		return (
			<div
				ref={this.scrubberRef}
				className={compiledClassNames}
				onMouseDown={this.onMouseDown}
				onDoubleClick={this.onDoubleClick}
			>
				{label && <label
					htmlFor={id}>{label}
				</label>}

				<ResetButton isOverriding={isOverriding} overrideReset={overrideReset} />
					
				<div
					ref={this.clickMaskRef}
					className="text-input-mask"
				>
					{isValid ? valueArray.map((item, index)=>{
						return <div key={index} data-type={item.type} data-index={index} >{item.value}</div>
					}): calculatedValue}
					{suffix !== undefined && <div className="suffix">
						{suffix}
					</div>}
				</div>
				{hideScrubberValue && <div className="value-placeholder">
					<div>Multiple</div>
				</div>}
				<div className={`text-input${dragging || wheeling ? ' clear-caret' : ''}${hideScrubberValue ? ' hide-value': ''}`}>
					<input
						type="text"
						id={id}
						spellCheck={false}
						value={hideScrubberValue ? 'Multiple' : calculatedValue}
						onKeyUp={this.handleKeyUp}
						onKeyDown={this.handleKeyDown}
						ref={this.scrubberInputRef}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						onChange={this.onChange}
						onSelect={this.onSelectionChange}
						onDragStart={this.onDragStart}
						autoComplete="off"

					/>
					{!hideScrubberValue && suffix !== undefined && <div className="suffix">
						{suffix}
					</div>}

				</div>
				<div className="ruler"
					ref={this.rulerRef}
					style={{
						backgroundPositionX: (moduloScrubProgress + scrubProgressZoomOffset) +'px',
						'--scrubber-ruler-size': rulerTickWidth+'px',
					}}
				></div>
				<div className="ruler-mask left-mask"></div>
				<div className="ruler-mask right-mask"></div>
			</div>
		)
	}

	onDragStart = (e)=>{
		e.preventDefault();
		e.stopPropagation();
	}

	handleKeyUp = (e)=>{

		switch(e.key){
			case "ArrowUp":
			case "ArrowDown":
				clearTimeout(this.incrementTimeout)
				this.incrementTimeout = setTimeout(()=>{
					this.setState({
						incrementing: false,
					})
				}, 30)
				break;

			default: 
				this.setState((prevState)=>{

					return {
						...prevState,
						updateFieldAfterInput: this.scrubberInputRef.current.value != this.valueOnKeyDown ? true : prevState.updateFieldAfterInput,
					}
				})

		}

	}

	handleKeyDown=(e)=>{

		if( e.metaKey){
			return
		}

		this.valueOnKeyDown = this.scrubberInputRef.current.value;


		if(this.state.incrementing){
			e.preventDefault();
			return;
		}

		switch(e.key){
			case "ArrowUp":
			case "ArrowDown":
				this.keyboardIncrementTimer = 150;
				clearTimeout(this.incrementTimeout);
				e.preventDefault();
				e.stopPropagation();

				this.setState({
					displayValue: this.state.lastValidValue,
					activeCursorIndex: this.findIndexOfNumberFromOtherIndex(Math.max(0, this.state.activeCursorIndex)),
					incrementing: true,
				},()=>{
					this.keyboardIncrement(e.key, e.altKey, e.shiftKey)		
				})
				break;

			case "Enter":
				e.preventDefault();
				this.onEnter()
				break;

			default:

		}

		// if the value has been hidden (un-synced padding values, etc)
		// then touch the form to trigger the onchange callback and make it display
		if( this.props.hideScrubberValue){

			this.setState({
				scrubberValueWasHidden: true
			})

			this.props.form.setFieldTouched(
				this.props.field.name,
				true,
				false
			)
		} else {
			this.setState({
				scrubberValueWasHidden: false
			})
		}


	}



	findIndexOfNumberFromOtherIndex = (activeCursorIndex = this.state.activeCursorIndex)=>{
		const {valueArray} = this.state.displayValue;

		if( valueArray[activeCursorIndex] && (
			valueArray[activeCursorIndex].type === 'whitespace' ||
			valueArray[activeCursorIndex].type === 'other' ||
			valueArray[activeCursorIndex].type === 'calc'
		)) {
			while( valueArray[activeCursorIndex] &&	valueArray[activeCursorIndex].type !=='num' ){
				activeCursorIndex++;
			}			
		} else if( valueArray[activeCursorIndex] && (
			valueArray[activeCursorIndex].type === 'unit' ||
			valueArray[activeCursorIndex].type === 'calcEnd'
		)){
			while( valueArray[activeCursorIndex] &&	valueArray[activeCursorIndex].type !=='num' ){
				activeCursorIndex--;
			}
		}

		return activeCursorIndex;
	}	

	incrementValue = (delta)=>{

		const {
			addDefaultUnitToUnitlessNumber,
			defaultUnit,
			allowCalc,
		} = this.props;

		const {
			isValid,
			hasCalc,
			valueArray
		} = this.state.displayValue;

		
		let activeCursorIndex = this.state.activeCursorIndex;		
		
		if( !isValid ){
			return {
				valueArray: valueArray || [],
				atMin: false,
				atMax: false,
			};
			// let inputValue = this.processInput(this.state.lastValidValue);
			// if (!inputValue.isValid){
			// 	return valueArray;
			// }
			// valueArray = inputValue.valueArray;
		}

		
		// return the current value array if the active cursor can't be found
		if( activeCursorIndex === -1 || activeCursorIndex >= valueArray.length){
			return {
				valueArray,
				atMin: false,
				atMax: false,
			};
		}

		let value = parseFloat(valueArray[activeCursorIndex].value);
		let unit = 'noUnit';

		if( valueArray[activeCursorIndex+1]?.type === 'unit'  ){
			unit = valueArray[activeCursorIndex+1].value;
		}

		if( unit === 'noUnit' && addDefaultUnitToUnitlessNumber && defaultUnit !== undefined){
			unit = defaultUnit;
		}

		// if(holdingShift){
		// 	delta = delta *10;
		// } else if ( holdingAlt ){
		// 	delta = delta *.1
		// }

		let step = this.getStep(unit);

		delta = delta*step
		value = value+delta;
	
		const newValueInfo = hasCalc && allowCalc ? {value, atMin: false, atMax: false} : this.clampValue(value, unit);

		return {
			valueArray: valueArray.map((item,index) => {
				if ( activeCursorIndex===index ){
					item.value = newValueInfo.value;
				} 
				return item;
			}),

			// min/max for the value that was just changed
			atMax: newValueInfo.atMax,
			atMin: newValueInfo.atMin
		}

	}


	keyboardIncrement =(key="ArrowUp", altKey=false, shiftKey=false)=>{
		
		clearTimeout(this.incrementTimeout)

		const {
			form,
			field,
			min,
			max,
			step,
		} = this.props;

		const {
			activeCursorIndex
		} = this.state;

		let delta = key==='ArrowUp' ? 1 : -1;

		if( altKey ){
			delta = delta*.1
		}

		if ( shiftKey ){
			delta = delta * 10
		}

		let valueArray = this.incrementValue(delta).valueArray;
		let displayValueString = valueArray.map(item=>item.value).join('');
		

		this.setState({
			displayValue: this.processInput(displayValueString),
			updateFieldAfterInput: true,
		}, ()=>{
			this.selectInInput(activeCursorIndex);			
		})

		this.keyboardIncrementTimer = Math.max(30, this.keyboardIncrementTimer*.9);

		this.incrementTimeout = setTimeout(()=>{
			this.keyboardIncrement(key, altKey, shiftKey)	
		}, this.keyboardIncrementTimer)

	}


	processInput = (value, options={})=>{

		const {
			field,
			form,
			defaultUnit,
			allowedUnits,
			numberOnlyMode,
			allowCalc,
			allowVar,
			min,
			max,
			addDefaultUnitToUnitlessNumber,
			cssTestProperty,
			alwaysShowUnits,
		} = this.props;

		const {
			forceFullValue = false,
			minimumDecimalPlaces = undefined,
			clampValues = true,
		} = options

		const alphaRule = /([a-zA-Z])/;

		value = value?.toString() || '';
		value = value.replace(/\,/g, '.');

		let calculatedValue = value;

		let chrType = 'num' // etc, unit, calc, var;
		let lastChrType = null;
		let chr = '';
		let valueArray = [];
		let valueArrayIndex = -1;

		// need %2 == 0 parentheses for calc and var to be valid, so count them here
		let hasCalc = 0;
		let hasVar = 0;
		let hasParenthesis = 0;
		let hasOther = 0;
		let hasNum = false;
		let hasUnit = false;
		let rangeStart = 0;
		let rangeEnd = 0;
		let unitIsDefaultUnit = false;

		for(var i = 0; i < value.length; i++){
		    rangeStart = i;
		    rangeEnd = i+1;
		    chr = value[i];
		    if( value.substr(i, 5) ==='calc('){

				// skip forward for calc(, but don't skip contents
		        chr = 'calc(';
		        i+=4;
		        rangeEnd = i;
		        chrType = 'calc';
		        hasCalc++;

		    } else if ( value.substr(i, 4) === 'var('){

				// totally skip over var() declarations
		        chr = '';
		        while( value[i] && value[i] !== ')' ){
		        	chr+=value[i] || '';
		            i++;
		        }
		        i--;

		        chrType = 'var';
		        hasVar++;

		    } else {

		        chr = value[i];

				// if value parses to a number, is a decimal, or is '-'' without a trailing space, it's a number
		        if(
		            !isNaN(parseInt(chr))
		        ){

		        	hasNum = true;
		            chrType = 'num';

		        // if it parses to an alphabet value, we assume it's part of a unit, and then queue it up for validating later
		        } else if(alphaRule.test(chr) || chr ==='%') {

		            chrType = 'unit';

		        // otherwise ) gets grouped to calc variables
		        } else if (
		        	chr ===')'
	        	){

	        		if( chrType === 'var' ){

	        			chrType = 'varEnd';
	        			hasVar++;

	        		} else if (chrType === 'calc' || hasCalc %2 ===1 ){

        				chrType = 'calcEnd'
				        hasCalc++;	        			
	        		} else {
	        			chrType = 'parenthesis'
	        			hasParenthesis++;
	        		}

		        } else if (chr==='('){
		        	chrType = 'parenthesis'
		        	hasParenthesis++
		        } else if ( chr===' ') {

		            chrType = 'whitespace';
		        } else if ( chr==='.' || chr==='-') {

		       		chrType = 'num';

		        } else {

		            chrType = 'other';
		            hasOther++;
		        }
		    }


		    if( lastChrType !== chrType ){		



		        valueArray.push({
		            value: chr,
		            type: chrType,
		            rangeStart: rangeStart,
		            rangeEnd: rangeEnd
		        });
		        valueArrayIndex++;

		    } else {
		        valueArray[valueArrayIndex].value += chr+'';
		        valueArray[valueArrayIndex].rangeEnd = rangeEnd;

		    }

		    lastChrType = chrType;
		    
		};

		/*
			we require a valid number if variables are not allowed or there are no variables
			we require a valid unit if numberOnlyMode is false
			we require a variable if there are no numbers and variables are allowed
		*/

		let isValid = true;
		let withinRange = true;
		let validUnit = true;

		const requiresNumber = !allowVar || (allowVar && hasVar < 2);
		const requiresVariable = allowVar && !hasNum;

		// prevent values like 5.00000000001 or 16.9999999999 from making it into the value array
		valueArray.forEach(item=>{
			if( item.type === 'num' && !isNaN(parseFloat(item.value)) ){
				item.value = parseFloat(parseFloat(item.value).toPrecision(9))
			}
		});


		// calc breaks open range limits as well as unit restrictions
		if( hasCalc === 0 || !allowCalc){

			let unit = 'etc';

			valueArray.forEach(item=>{
				if( item.type === 'unit'){

					hasUnit = true;
					unit = item.value;

					if( allowedUnits.indexOf(item.value) === -1 ){
						validUnit = false;
					}

					if( item.value === defaultUnit){
						unitIsDefaultUnit = true;
					}
				}
			});


			if( !hasUnit){

				if( addDefaultUnitToUnitlessNumber && defaultUnit !== undefined){
					unit = defaultUnit
				} else {
					unit = 'noUnit'
				}

			} 

			valueArray.forEach(item=>{

				if( item.type ==='num' && !isNaN(parseFloat(item.value)) ){	

					if( clampValues){
						item.value = this.clampValue(item.value, unit).value
					} else {
						let unitMin = typeof min === 'object' ?  min[unit] : min;
						let unitMax = typeof max === 'object' ?  max[unit] : max;

						if( unitMin !== undefined){

							if ( item.value < unitMin){
								withinRange = false;
							}

						}

						if( unitMax !== undefined){

							if( item.value > unitMax){
								withinRange = false;
							}

						}
					}


				}

			})

			
		}

		if( !hasUnit && allowedUnits.indexOf('noUnit') > -1){
			validUnit = true;
		}

		let inputOnlyContainsNumber = valueArray.length == 1 && valueArray[0].type ==='num';

		if( numberOnlyMode ){

			if( hasCalc > 0 ||
				hasVar > 0 ||
				hasParenthesis%2 == 1 ||
				hasNum === 0 ||
				hasOther > 0 ||
				hasUnit ||
				!hasNum
			){
				isValid = false;
			} 

		} else {

			this.validatorTest.style[cssTestProperty] = '';

			// if we only have a number, add a default unit to it before testing
			if( inputOnlyContainsNumber && defaultUnit !== undefined && addDefaultUnitToUnitlessNumber){
				this.validatorTest.style[cssTestProperty] = value+defaultUnit;
			} else {
				this.validatorTest.style[cssTestProperty] = value;				
			}

			// check to see if's valid as a css string
			if( this.validatorTest.style[cssTestProperty] === '' ){

				if( !hasUnit && allowedUnits.indexOf('noUnit') > -1 ){
					isValid = true;
				} else {
					isValid = false;
				}
				

			// if the property is valid css but using the wrong unit, mark it invalid
			} else if( validUnit ){
				isValid = true;
			} else {
				isValid = false;
			}

			if( !allowCalc && hasCalc > 0){
				isValid = false;
			}

			if( !allowVar && hasVar > 0){
				isValid = false;
			}

			if( requiresNumber && !hasNum){
				isValid = false;
			}

			if( !hasNum && hasVar < 2){
				isValid = false;
			}

			// if missing a parenthesis, mark it invalid
			if( hasCalc % 2 !== 0){
				isValid = false;
			}

			if( hasVar % 2 !== 0){
				isValid = false;
			}

		}

		if(
			numberOnlyMode === true ||
			(hasCalc === 0 && hasVar === 0) || 
			(!allowCalc && !allowVar)
		){

			let activeUnit = valueArray.find(item=>item.type==='unit')?.value || 'noUnit';

			if( activeUnit === 'noUnit' && addDefaultUnitToUnitlessNumber && defaultUnit !== undefined){
				activeUnit = defaultUnit;
				unitIsDefaultUnit = true;
			}			

			let stringToFunction = "return "+ valueArray.map(item=>item.type === 'unit' ? '' : item.value).join('');
			let result = undefined;
			try {
				result = Function(stringToFunction)();
				result = this.clampValue(result,activeUnit).value;
				// clear scientific notation from calculations
				if( activeUnit === 'e' && Math.abs(result) < .0001){
					activeUnit = 'noUnit';
				}


				if( activeUnit === 'noUnit'){
					calculatedValue = result;
				} else if(!isNaN(parseFloat(calculatedValue)) ) {
					calculatedValue = result+activeUnit;	
				}

			} catch (error) {
				result = undefined;
				calculatedValue = value;
			}
		}

		// hide the units if the correct scenario is met (if it would be unambiguous as to what the unit is: no calc, no var, etc)
		if(!forceFullValue && unitIsDefaultUnit && addDefaultUnitToUnitlessNumber  && hasCalc === 0 && hasVar === 0 && isValid && !alwaysShowUnits){
			valueArray = valueArray.filter(item=>item.type !=="unit");
			calculatedValue = valueArray.map(item=>item.value).join('');
		}

		// if we're dragging and need to force a number of places on the number being dragged, add it here
		// if there's an activeCursor Index, enforce that value
		// otherwise, enforce it on all indexes
		if( this.state !== undefined ){


			if (minimumDecimalPlaces == 0 || minimumDecimalPlaces == undefined){

				// remove any decimal places left
				valueArray.forEach((item, index)=>{
					if( item.type === 'num' && ( this.state.activeCursorIndex === -1 || index == this.state.activeCursorIndex ) && !isNaN(parseFloat(item.value)) ){
						item.value = parseFloat(item.value)+'';
					} 
				});

			} else {

				valueArray.forEach((item, index)=>{
					if( item.type === 'num' && ( this.state.activeCursorIndex === -1 || index == this.state.activeCursorIndex ) && !isNaN(parseFloat(item.value)) ){

						let decimalPlaces = item.value?.toString().split('.')[1]?.length || 0;				
						if( item.value?.toString().indexOf('.') == -1){
							item.value = item.value + '.'
						}				
						const additionalDecimalPlaces = Math.max(0, minimumDecimalPlaces-decimalPlaces);
						item.value = item.value + '0'.repeat(additionalDecimalPlaces);						
					} 
				});		

			}

			calculatedValue = valueArray.map(item=>item.value).join('');

		}


		return {
			withinRange,
			hasCalc,
			hasVar,
			hasUnit,
			valueArray,
			isValid,
			calculatedValue,
		};
	}

	getStep = (unit)=>{

		let step = 1;

		if( typeof this.props.step === 'object' ){

			const steps = {...unitIncrements, ...this.props.step}

			if( steps[unit] !== undefined ){
				step = steps[unit] || 1;
			} else {
				step = steps.etc || 1;
			}

		} else {

			if( this.props.step !== undefined){
				step = this.props.step || 1;
			} else {
				if( unitIncrements[unit] !== undefined ){
					step = unitIncrements[unit] || 1;
				} else {
					step = unitIncrements.etc || 1;
				}				
			}
		}
		return step;
	}

	clampArray = (valueArray)=>{

		const {
			addDefaultUnitToUnitlessNumber,
			defaultUnit
		} = this.props;

		return valueArray.map((item, index)=>{

			if( item.type === 'num'){

				let unit = 'noUnit';

				if( valueArray[index+1]?.type === 'unit'  ){
					unit = valueArray[index+1].value;
				}

				if( unit === 'noUnit' && addDefaultUnitToUnitlessNumber && defaultUnit !== undefined){
					unit = defaultUnit;
				}

				item.value = this.clampValue(item.value,unit, valueArray).value;

			}

			return item;

		})

	}

	/* 
		returns {
			value,
			atMin: false,
			atMax: false
		}
	*/
	clampValue = (value, unit) => {

		const {
			min,
			max,
			loopValues,
		} = this.props;


		// get unit
		unit = (unit === undefined || unit === '') ? 'noUnit' : unit;
		
		let unitMin = undefined;
		let unitMax = undefined;
		let atMin = false;
		let atMax = false;
		
		if ( typeof min === 'object' ){
			if( min[unit]){
				unitMin = min[unit];
			} else{
				unitMin = min.etc;
			}
			
		} else {
			unitMin = min;
		}

		if ( typeof max === 'object' ){
			if( max[unit]){
				unitMax = max[unit];
			} else{
				unitMax = max.etc;
			}

		} else {

			unitMax = max;
		}

		let step = this.getStep(unit);
		let newValue = parseFloat(value);

		if( loopValues ){

			if( !isNaN(unitMin) && !isNaN(unitMax) && unitMax > unitMin) {

				const range = unitMax-unitMin;
				while(newValue > unitMax){
					newValue = newValue - range;
				}

				while(newValue < unitMin){
					newValue = newValue + range;
				}

			}

		}

// 		if( altKey ){
// 			step = step*.1
// 		}
// 
// 		if ( shiftKey ){
// 			step = step * 10
// 		}

		let inv = 1 / step;
		newValue = Math.round(newValue * inv) / inv;

		let stepString = step.toString();
		let decimalPlaces = Math.min(4, (stepString.indexOf('.') > -1 ? stepString.split('.').pop().toString().length : 0) );

		if( step < 1 ){
			decimalPlaces = Math.max(1, decimalPlaces);
		}		


		if( !isNaN(unitMin) ){
			newValue = Math.max(unitMin, newValue);
			if( newValue <= unitMin){
				atMin = true;
			}
		}

		if ( !isNaN(unitMax) ) {
			newValue = Math.min(unitMax, newValue);
			if( newValue >= unitMax){
				atMax = true;
			}
		}

		// avoid scientific notation for numbers close to zero
		// no accuracy in this range should be necessary anyway
		if( newValue < .000001 && newValue > -.000001){
			newValue = 0;
		}

		if( isNaN(decimalPlaces) ){
			newValue = newValue.toString();
		} else {
			newValue = newValue.toFixed(decimalPlaces);
		}

		if( newValue === '-0'){
			newValue = '0'
		}

		return {
			value: parseFloat(newValue),
			atMin,
			atMax
		};

	}


	validateAndSetField = (value) => {
		const {
			form,
			field,
			numberOnlyMode,
			addDefaultUnitToUnitlessNumber,
			defaultUnit,
			allowCalc,
			coerceNumberToString,
			alwaysShowUnits = false,
		} = this.props;

		const {
			hasFocus,
			dragging,
			wheeling,
			incrementing,

		} = this.state;

		let inputValue = this.processInput(value, {
			forceFullValue: true
		});

		let displayValueString = value;

		// the field value can potentially be different than the display value
		let fieldValueString = inputValue.calculatedValue;

		if( !numberOnlyMode ){

			if(
				!inputValue.hasUnit &&
				defaultUnit !== undefined &&
				inputValue.hasVar === 0 &&
				inputValue.hasCalc === 0 &&
				addDefaultUnitToUnitlessNumber &&
				inputValue.isValid
			){
				fieldValueString = displayValueString + defaultUnit;

				// add leading 0 to decimal
				fieldValueString = fieldValueString.replace(/^\.(\d+)/, '0.$1');

			}

		}




		// coerce the field value to the correct type
		// see note about coerceNumberToString in defaultProps notes
		if( numberOnlyMode) {

			if( coerceNumberToString ){
				fieldValueString = parseFloat(fieldValueString).toString();
			} else {
				fieldValueString = parseFloat(fieldValueString);
			}

			displayValueString = parseFloat(displayValueString);

		} else {

			fieldValueString = fieldValueString.toString();
			displayValueString = displayValueString.toString();

		}

		if(inputValue.isValid ){

			if( (field.value !== fieldValueString ) && this.valueIsDefined(fieldValueString) ){

				form.setFieldValue(
					field.name, 
					fieldValueString,
					false
				);

				if ( this.props.onChange ) {
					this.props.onChange(fieldValueString);
				}

			}

			this.setState({
				lastValidValue: inputValue
			});

		}

		this.setState({
			updateFieldAfterInput: false,
		});
	}

	valueIsDefined = (value)=>{
		if(
			typeof value === 'number' || 
			typeof value === 'string'
		){
			// make sure funky values havent been stringified
			if( typeof value === 'string'){
				if (
					value.indexOf('undefined') > -1 ||
					value.indexOf('NaN') > -1
				) {
					return false
				}
			}

			if( Number.isNaN(value) ){
				return false;
			}

			return true;

		} else {
			return false;
		}
	}


	componentDidMount(){
		
		const {
			form,
			field,
			alwaysShowUnits,
		} = this.props;

		if( this.valueIsDefined(field.value) ){

			const inputValue = this.processInput(this.clampArray(this.processInput(field.value).valueArray).map(item=>item.value).join(''));

			this.setState({
				displayValue: inputValue,
			})

		} else {
			this.displayDefaultOverNull({
				forceFieldUpdate: false,
			});
		}

		// disable for now
		if( this.scrubberRef.current){
			// this.scrubberRef.current.addEventListener('wheel', this.handlePointerMove);
		}

		globalUndoManager.on('yjs-before-stackitem-applied', this.snapshotValue);

		resizeMap.set(this.clickMaskRef.current, this);
		resizeObserver.observe(this.clickMaskRef.current);
	}

	componentDidUpdate(prevProps, prevState){

		const {
			field,
			form,
			defaultUnit,
			alwaysShowUnits,
			addDefaultUnitToUnitlessNumber,
		} = this.props

		const {
			field: prevField,
			form: prevForm,
		} = prevProps;

		const {
			displayValue,
			dragging,
			wheeling,
			incrementing,
			hasFocus,
			activeCursorIndex,
			updateFieldAfterInput,
		} = this.state;

		const {
			dragging: wasDragging,
			wheeling: wasWheeling,
		} = prevState;

		const { 
			displayValue: prevDisplayValue
		} = prevState;

		/*
			The field value can change while a user is scrubbing, dragging, wheeling incrementing etc
			when this happens, we should make sure that the display value matches it unless the user is interacting with the form
			this if/then structure will control when to update the display value: if it's being interacted-with, wait until the user is finished
		 */

		let currentValueIsDefined = this.valueIsDefined(field.value);

		if (updateFieldAfterInput !== prevState.updateFieldAfterInput && updateFieldAfterInput  ) {
			this.validateAndSetField(displayValue.calculatedValue)
		}

		// if the field value changed, update the display value 
		// we can have null-y values, we're just checking to make sure we're not
		// comparing NaN against NaN or else we will enter an infinite loop
		if(
			!hasFocus && (
				(field.value !== prevField.value && !Number.isNaN(field.value) && !Number.isNaN(prevField.value) )  ||
				(!this.valueIsDefined(prevField.value) && currentValueIsDefined )
			)
		){

			if( currentValueIsDefined ){

				if( field.value.toString() !== prevField.value?.toString() ){

					let processedCurrentValue = this.processInput(field.value, {
						minimumDecimalPlaces: dragging ? this.state.minimumDecimalPlaces : 0,
					});

					if( processedCurrentValue.isValid){
						this.setState({
							displayValue: processedCurrentValue,
							lastValidValue: _.cloneDeep(processedCurrentValue)
						});							
					} else {
						this.setState({
							displayValue: processedCurrentValue,
						});							
					}

				}


			} else {

				this.displayDefaultOverNull();
			}

		} 


		// after finishing a drag or wheel, find selected number and set the selection to its right
		if (
			wasDragging && !dragging ||
			wasWheeling && !wheeling
		){
			
			if( wasWheeling && !wheeling ){
				this.selectInInput(this.findIndexOfNumberFromOtherIndex(activeCursorIndex), 'collapseRight')
			}

		}

		if( wasDragging !== dragging){
			if( dragging){
				this.props.onScrubStart?.(this.props.field);
			} else {
				this.props.onScrubEnd?.(this.props.field);
			}
		}

	}

	componentWillUnmount(){

		cancelAnimationFrame(this.dragFrame)
		resizeObserver.unobserve(this.clickMaskRef.current);
		resizeMap.delete(this.clickMaskRef.current);

		if( this.scrubberRef.current){
			this.scrubberRef.current.removeEventListener('wheel', this.handlePointerMove);
		}

		globalUndoManager.resume();
		globalUndoManager.off('yjs-before-stackitem-applied', this.snapshotValue);
		globalUndoManager.off('yjs-stackitem-applied', this.compareSnapshottedValue);

		this.debouncedSelectInInput.cancel();
		clearInterval(this.requestPointerLockInterval);	
		clearTimeout(this.wheelTimeout);
		clearTimeout(this.incrementTimeout)	
		clearTimeout(this.initialFastModeCheck);
		
		document.removeEventListener('pointerlockchange', this.onPointerLockChange)		
		document.exitPointerLock();

		window.removeEventListener('pointermove', this.handlePointerMove)
		window.removeEventListener('pointercancel', this.handlePointerUp)
		window.removeEventListener('pointerup', this.handlePointerUp)
		window.removeEventListener("click", this.onClickCapture, true);
		document.body.classList.remove('scrubbing');

	}

	displayDefaultOverNull = (options={})=>{

		const {
			form,
			field,
			numberOnlyMode,
			defaultUnit,
			allowCalc,
			defaultValue,
		} = this.props;

		const {
			forceFieldUpdate= false
		} = options;

		// here we bypass the validate-and-set step and set the display values directly
		// even when the field value is nully/undefined

		if( defaultValue !== undefined ){

			let processedDefault = this.processInput(defaultValue);

			this.setState({
				lastValidValue: _.cloneDeep(processedDefault),
				displayValue: processedDefault,
			})

		} else {


			let minValue = this.clampValue(-9e9, defaultUnit).value;


			// sanity check: if there is no minimum, just make it 0
			if( minValue === -9e9 ) {
				minValue = this.clampValue(0, defaultUnit).value
			}


			let processedMinValue = this.processInput(minValue);

			this.setState({
				lastValidValue: _.cloneDeep(processedMinValue),
				displayValue: processedMinValue,
			})			
		}

		if( forceFieldUpdate){
			this.setState({
				updateFieldAfterInput: true
			});
		}
	}	

	snapshotValue = ()=>{
		this.snapshotValue = this.props.field.value;
		globalUndoManager.once('yjs-stackitem-applied', this.compareSnapshottedValue)
	}

	compareSnapshottedValue = ()=>{

		setTimeout(()=>{
			if( this.props.field.value !== this.snapshotValue){

				if( this.valueIsDefined(this.props.field.value) ){
					this.setState({
						displayValue: this.processInput(this.props.field.value),
					})
				} else {
					this.displayDefaultOverNull();
				}

			}
		}, 100)
		
	}

	onChange = (e) => {
		// If the range value is hidden and we're manually entering some value into the input
		if( this.props.hideScrubberValue && this.scrubberInputRef.current.value.includes('Multiple') ){

			let currentValue = this.scrubberInputRef.current.value.replace('Multiple', '');
			// Strip out invalid character from range value change
			this.setState({
				displayValue:this.processInput(currentValue)
			});

			return
		}

		if( this.state.scrubberValueWasHidden){
			this.setState({
				displayValue: this.processInput(e.nativeEvent.data),
			})
			return
		}

		const displayValue = this.processInput(this.scrubberInputRef.current.value);
		displayValue.calculatedValue = this.scrubberInputRef.current.value;

		this.setState({
			displayValue: displayValue,
		})
	}

	onSelectionChange=()=>{

		const {
			displayValue,
			dragging,
			wheeling,
			incrementing
		} = this.state;

		if(dragging || wheeling || incrementing ){
			return;
		}

		const {
			valueArray,
			isValid,
		} = displayValue;

		const activeCursorRange = [this.scrubberInputRef.current.selectionStart, this.scrubberInputRef.current.selectionEnd];
		const activeCursorIndex = _.findIndex(valueArray,(item)=>item.rangeStart <= activeCursorRange[1] && item.rangeEnd >= activeCursorRange[1]);

		this.setState({
			activeCursorRange,
			activeCursorIndex: isValid ? activeCursorIndex : -1
		})		

	}

	onMouseDown = (e) => {

		if ( e.button === 2 || !this.scrubberInputRef.current || !this.clickMaskRef.current ){
			return
		}


		// blur the active element (addresses issue #217)
		let activeElement = document.activeElement;
		activeElement.blur();

		if( this.props.onMouseDown ){
			this.props.onMouseDown()
		}

		clearTimeout(this.initialFastModeCheck);

		// disable slow-mode for now
		// this.initialFastModeCheck = setTimeout(()=>{
		// 	this.initialFastModeCheck = null;
		// 	if(Math.abs(this.allDelta) < 2 ){
		// 		this.setState({
		// 			fastMode: false
		// 		})
		// 	}
		// }, 500);
		this.dragFrame = requestAnimationFrame(this.flushDragDelta);		

		// safari's pointer lock implementation shoves a banner down into the window, so we'll use regular pointermove instead
		// Firefox's pointer lock jumps the cursor
		// chrome's pointer lock implementation got annoying

		// if ( helpers.isChrome() ){
		// 	this.requestPointerLockInterval = setInterval(()=>{
		// 		if( this.state.dragging){
		// 			document.addEventListener('pointerlockchange', this.onPointerLockChange);
		// 			try {
		// 				this.scrubberInputRef.current.requestPointerLock();
		// 			} catch(e) {
		// 				console.error(e);
		// 			}
		// 			clearInterval(this.requestPointerLockInterval);	
		// 		}
		// 	}, 150)
		// }

		let allowDefault = false;

		let pointerMaskIndex = parseInt(Array.from(this.clickMaskRef.current.children).find(el=>{

			if(el.dataset.type ==='num' || el.dataset.type ==='unit'  || el.dataset.type ==='calc'){

				const rect = el.getBoundingClientRect();
				const isInRect =(
					e.clientX > rect.left &&
					e.clientX <= rect.left+rect.width &&
					e.clientY > rect.top &&
					e.clientY <= rect.top+rect.height
				);

				if( isInRect && ( el.dataset.type ==='unit'  || el.dataset.type ==='calc' ) ){
					allowDefault = true;
				}
				return isInRect;

			} else {
				return false;				
			}
		})?.dataset.index);

		if( isNaN(pointerMaskIndex) ){
			pointerMaskIndex = -1;
		}

		if( !allowDefault){
			e.preventDefault();
		}

		this.initialMovement = false;
		this.lastPointerDownPosition = e.clientX;
		this.allDelta = 0;

		const rect = this.rulerRef.current.getBoundingClientRect();
		const rulerWidth = rect.width;
		const pointerDownPosition = e.clientX - rect.left;

		this.setState({
			rulerAtMax: false,
			rulerAtMin: false,
			rulerWidth: rulerWidth,
			fastMode: true,
			pointerDown: true,
			pointerMaskIndex,
			pointerDownPosition
		})

		window.addEventListener('pointermove', this.handlePointerMove)
		window.addEventListener('pointercancel', this.handlePointerUp);
		window.addEventListener('pointerup', this.handlePointerUp)	
		window.addEventListener("click", this.onClickCapture, true)

	}
	onDoubleClick = (e)=>{
		this.selectInInput(-1);
	}

	flushDragDelta = ()=>{

		const {
			wheeling,
			dragging,
			displayValue,
			rulerTickWidth,
			fastMode,
			pointerDown,
			scrubProgress,
			scrubProgressAtRulerStop,
			pointerDownPosition,
			rulerAtMax,
			rulerAtMin,
			minimumDecimalPlaces,
		} = this.state;


		const {
			fastPixelsPerDelta,
			slowPixelsPerDelta
		} = this.props;

		let pixelsPerDelta = fastMode ? fastPixelsPerDelta : slowPixelsPerDelta

		let continueAnimation = false;
		if( wheeling || dragging || pointerDown){

			continueAnimation = true;

		} else if(!wheeling && !dragging){

			if ( Math.abs(scrubProgress) > 0.1 ){
				continueAnimation = true;
			}

			if(
				(fastMode && Math.abs(rulerTickWidth - 10) > .1) ||
				(!fastMode && Math.abs(rulerTickWidth - 20) > .1)
			){
				continueAnimation = true
			}
		}


		if( continueAnimation){
			this.dragFrame = requestAnimationFrame(this.flushDragDelta)			
		} else {
			this.setState({
				rulerTickWidth: fastMode ? 10 : 20,
				scrubProgress: 0
			})				
			return;
		}

		let dragDelta = this.dragDelta;
		let newRulerAtMin = rulerAtMin;
		let newRulerAtMax = rulerAtMax;
		let newScrubProgressAtRulerStop = scrubProgressAtRulerStop;

		let delta = 0;
		let curveThreshold = 3
		let curveSpeed = 1

		this.accumulatedDelta = this.accumulatedDelta + dragDelta;
		this.dragDelta = 0;

		let displayValueString = displayValue;

		let newTickWidth = rulerTickWidth
		if( fastMode ){
			newTickWidth = newTickWidth*.78 + 10*.22
			if( newTickWidth < 10.05){
				newTickWidth = 10;
			}				
		} else {
			newTickWidth = newTickWidth*.5 + 20*.5
			if( newTickWidth > 19.95){
				newTickWidth = 20;
			}
		}		

		if( wheeling || dragging ){

			// if accumulated delta is over the pixelsPerDelta threshold, reset that value and update
			if( Math.abs(this.accumulatedDelta) > pixelsPerDelta || (wheeling && this.accumulatedDelta !== 0)){

				if( this.accumulatedDelta < 0){
					delta = Math.ceil(this.accumulatedDelta/pixelsPerDelta)
					if( delta < -1 && fastMode){
						delta = delta + 1;
						const amountOverThreshold  = delta*pixelsPerDelta;
						delta = (delta+amountOverThreshold)-1;
					} else {
						delta = -1;
					}


				} else{

					delta = Math.floor(this.accumulatedDelta/pixelsPerDelta)
					if( delta > 1 && fastMode){
						delta = delta - 1;
						const amountOverThreshold = delta*pixelsPerDelta;
						delta = (delta+amountOverThreshold) + 1;
					} else {
						delta = 1;
					}		
				}
				
				this.initialMovement = false;
				this.accumulatedDelta = 0;

			}


			let newValueInfo = this.incrementValue(delta);

			newRulerAtMax = newValueInfo.atMax;
			newRulerAtMin = newValueInfo.atMin;

			if(
				(newRulerAtMax && !rulerAtMax) ||
				(newRulerAtMin && !rulerAtMin)
			){
				newScrubProgressAtRulerStop = scrubProgress
			}			

			if( fastMode ){
				this.allDelta = this.allDelta+delta;
			} else {
				this.allDelta = this.allDelta+delta*2;	
			}

			let nextPosition = 0;
			if( newRulerAtMax || newRulerAtMin){
				nextPosition = this.allDelta*.8 + newScrubProgressAtRulerStop*.2;

				if( newRulerAtMax){
					let amountOver = nextPosition - newScrubProgressAtRulerStop;
					amountOver = amountOver *(1/((amountOver/2)+1));

					nextPosition = newScrubProgressAtRulerStop + amountOver;

				} else if (newRulerAtMin){

					let amountUnder = newScrubProgressAtRulerStop - nextPosition;
					amountUnder = amountUnder *(1/((amountUnder/2)+-1));

					nextPosition = newScrubProgressAtRulerStop + amountUnder;
				}

			} else {
				nextPosition = this.allDelta*.8 + scrubProgress*.2
			}

			// clamp values , remove units, & add decimal from value if necessary
			const newValueArray = this.clampArray(newValueInfo.valueArray);
			let minimumDecimalPlaces = newValueArray[this.state.activeCursorIndex].value.toString().split('.')[1]?.length || 0;
			minimumDecimalPlaces = Math.max(this.state.minimumDecimalPlaces, minimumDecimalPlaces);
			displayValueString = newValueArray.map(item=>item.value).join('');	

			const displayValue = this.processInput(displayValueString , {
				forceFullValue: false,
				minimumDecimalPlaces: minimumDecimalPlaces
			});


			this.setState((prevState)=>{

				return {
					...prevState,
					minimumDecimalPlaces: minimumDecimalPlaces,
					scrubProgressAtRulerStop: newScrubProgressAtRulerStop,
					rulerAtMax: newRulerAtMax,
					rulerAtMin: newRulerAtMin,
					rulerTickWidth: newTickWidth,
					scrubProgress: nextPosition,
					displayValue: displayValue,
					updateFieldAfterInput: prevState.displayValue.calculatedValue !== displayValue.calculatedValue ? true : prevState.updateFieldAfterInput ,
				}
			})
		} else {
			
			delta = Math.max(-10, Math.min(10, delta))

			let nextPosition =  (scrubProgress%10)*.82;
			if( newRulerAtMin && scrubProgress > 0){
				nextPosition = scrubProgress-10;
			} else if ( newRulerAtMax && scrubProgress < 0){
				nextPosition = scrubProgress+10;				
			}

			this.setState({
				rulerAtMax: false,
				rulerAtMin: false,
				rulerTickWidth: newTickWidth,
				scrubProgress: nextPosition,
			});	
		}

	}


	selectInInput = (targetIndex, selection)=>{

		this.scrubberInputRef.current.focus();	

		const value = this.scrubberInputRef.current.value;
		const valueArray = this.processInput(value).valueArray;

		let selectionStart = 0;
		let selectionEnd = 0;

		// select everything
		// force-select everything if hiding the range value, because the next step is entering a value
		if( isNaN(targetIndex) || targetIndex === -1 || this.props.hideScrubberValue ){

			selectionStart = 0;
			selectionEnd = (value+'').length;

		} else if(this.state.displayValue.isValid && !isNaN(targetIndex)){

			selectionStart=valueArray[targetIndex].rangeStart;
			selectionEnd = valueArray[targetIndex].rangeEnd;
			
		}

		if(selection==='collapseRight'){
			selectionStart = selectionEnd
		} else if (selection==='collapseLeft'){
			selectionEnd = selectionStart
		} 

		this.scrubberInputRef.current.setSelectionRange(selectionStart,selectionEnd);			

	}

	handlePointerMove = (e) => {

		const isWheel = e.type === 'wheel';

		const {
			form,
			field,
			min,
			max,
			loopValues,
			defaultUnit,
			addDefaultUnitToUnitlessNumber,
			fastPixelsPerDelta,
			slowPixelsPerDelta,
			fastMode,	
		} = this.props;

		const {
			pointerMaskIndex,
			dragging,
			hasFocus,
			wheeling,
			displayValue,
			activeCursorIndex,
			lastValidValue
		} = this.state;

		if( !lastValidValue ){
			this.displayDefaultOverNull({
				forceFieldUpdate: true,
			});
			return;
		}

		let {
			valueArray
		} = lastValidValue;


		clearTimeout(this.resetInitialMovementTimeout);

		const pointerDownOnNonNumber = valueArray[pointerMaskIndex] && valueArray[pointerMaskIndex]?.type !=='num';

		// if we're dragging around in the unit area, make the drag a little longer to initiate
		const pointerMoveDistanceToStartDrag = pointerDownOnNonNumber ? 45 : 1

		if( isWheel){

			clearTimeout(this.wheelTimeout);

			// don't allow scroll-wheel interaction unless we have focus
			if( !hasFocus){
				return;
			}			

			this.wheelTimeout = setTimeout(()=>{
				this.initialMovement = true;
				this.accumulatedDelta = 0;
				this.allDelta = 0;
				this.dragDelta = 0;
				this.setState({
					wheeling: false,
					minimumDecimalPlaces: 0,
				}, ()=>{
					this.handlePointerUp();
				})
			}, 500)


		} else {

			//uncomment to periodically reset mouse delta when the mouse is still
			// this.resetInitialMovementTimeout = setTimeout(()=>{
			// 	this.accumulatedDelta = 0;
			// 	this.lastDelta = 0;
			// 	this.dragDelta = 0;
			// 	this.initialMovement = true;
			// }, 200)

		}

		if(
			Math.abs(this.lastPointerDownPosition - e.clientX) < pointerMoveDistanceToStartDrag &&
			!dragging &&
			!isWheel
		){
			return;
		}

		if( isWheel ){
			e.preventDefault();				
			e.stopPropagation();
		} else {
			e.preventDefault();	
		}
		

		// start drag on this pointer move, then actually calc drag on subsequent moves
		if(!dragging && !wheeling){

			// don't allow incrementing when we're in the red.
			if( !displayValue.isValid ){
				this.setState({
					displayValue: _.cloneDeep(this.state.lastValidValue)
				})
				return;
			}

			globalUndoManager.pause();

			if( this.props.hideScrubberValue){

				this.setState({
					scrubberValueWasHidden: true
				})

				this.props.form.setFieldTouched(
					this.props.field.name,
					true,
					false
				)
			} else {
				this.setState({
					scrubberValueWasHidden: false
				})
			}

			if( isWheel ){

				this.dragDelta = 0;

				let wheelDelta = e.deltaY == 0 ? e.deltaX : e.deltaY;

				if(e.shiftKey){
					this.accumulatedDelta = wheelDelta > 0 ? 8 : -8;
				}  else {
					this.accumulatedDelta = wheelDelta > 0 ? 1 : -1;
				}

				const activeCursorIndex = this.findIndexOfNumberFromOtherIndex(Math.max(0, activeCursorIndex))
				// as we drag, we should only allow the number of decimal points to grow as the number gets moved
				// to prevent the display from shaking back and forth as it goes from 3->3.1 -> 4 etc
				
				this.setState({
					minimumDecimalPlaces : this.state.displayValue.valueArray[activeCursorIndex]?.value?.toString().split('.')[1]?.length || 0,
					activeCursorIndex: activeCursorIndex,
					wheeling: true
				}, ()=>{
					this.dragFrame = requestAnimationFrame(this.flushDragDelta);
				})
				return;

			} else {

				const activeCursorIndex = this.findIndexOfNumberFromOtherIndex(Math.max(0, pointerMaskIndex))

				this.setState({
					activeCursorIndex: activeCursorIndex,
					dragging: true,
					wheeling: false,
					minimumDecimalPlaces : this.state.displayValue.valueArray[activeCursorIndex]?.value?.toString().split('.')[1]?.length || 0,					
					showResizingCursor: true,
				},()=>{
					document.body.classList.add('scrubbing');
					this.scrubberInputRef.current.style.cursor = 'ew-resize';
				});

				this.lastPointerDownPosition = e.clientX;
				this.initialMovement = true;
				this.dragDelta = 0;	
				this.accumulatedDelta = 0;
				return;
			}			
		}

		let dragDelta = 0;

		
		if( isWheel){

			let wheelDelta = e.deltaY == 0 ? e.deltaX : e.deltaY;

			if(e.shiftKey){
				this.accumulatedDelta = wheelDelta > 0 ? 8 : -8;
			}  else {
				this.accumulatedDelta = wheelDelta > 0 ? 1 : -1;
			}	


		} else if (
			document.pointerLockElement === this.scrubberInputRef.current ||
			document.mozPointerLockElement === this.scrubberInputRef.current
		) {

			dragDelta = e.movementX;

		} else {

			dragDelta = (e.clientX - this.lastPointerDownPosition);
			this.lastPointerDownPosition = e.clientX;			
		}

		this.dragDelta = dragDelta+this.dragDelta;

	}

	// when an element has pointer lock, then the element can emit mousemove events with new moveX/Y properties
	// https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API#extensions_to_mouse_events
	onPointerLockChange = (e) =>{

		if(
			document.pointerLockElement === this.scrubberInputRef.current ||
			document.mozPointerLockElement === this.scrubberInputRef.current
		) {
			FRONTEND_DATA.editorOverlayAPI?.setHoveredElements([]);
			FRONTEND_DATA.editorOverlayAPI.lock();
			window.removeEventListener('pointermove', this.handlePointerMove)			
			this.scrubberInputRef.current.addEventListener('mousemove', this.handlePointerMove);
		} else {
			FRONTEND_DATA.editorOverlayAPI.unlock();
			document.removeEventListener('pointerlockchange', this.onPointerLockChange)			
			this.scrubberInputRef.current.removeEventListener('mousemove', this.handlePointerMove);
		}

	}

	handlePointerUp = (event) => {

		const {
			activeCursorIndex,
			pointerMaskIndex,
			dragging,
			displayValue,
			fastMode,
		} = this.state;



		if( event){
			event.preventDefault();
		}
		
		if(dragging || !fastMode){
			event.stopPropagation();
			this.scrubberInputRef.current.blur()
			// this.selectInInput(this.findIndexOfNumberFromOtherIndex(activeCursorIndex) );
		} else if (displayValue.isValid) {
			if( pointerMaskIndex === -1){
				this.selectInInput(this.findIndexOfNumberFromOtherIndex(0));
			} else {
				this.selectInInput(pointerMaskIndex);
			}
			
		}

		if( this.props.onPointerUp ){
			this.props.onPointerUp()
		}
	
		document.exitPointerLock();
		clearInterval(this.requestPointerLockInterval);	

		clearTimeout(this.initialFastModeCheck);

		this.allDelta = 0;

		this.setState({
			minimumDecimalPlaces: 0,
			fastMode: true,
			pointerDown: false,
			dragging: false,
			showResizingCursor: false,
			displayValue: this.processInput(displayValue.calculatedValue, {minimumDecimalPlaces: 0})
		});
		
		window.removeEventListener('pointermove', this.handlePointerMove)
		window.removeEventListener('pointercancel', this.handlePointerUp)
		window.removeEventListener('pointerup', this.handlePointerUp);

		globalUndoManager.resume();

		this.scrubberInputRef.current.removeEventListener('mousemove', this.handlePointerMove);
		document.body.classList.remove('scrubbing');

	}

	onClickCapture = (event) =>{

		if ( // prevent click functionality:
			// when dragging
			this.state.dragging
			// when clicking on the override reset button
			|| event.target.classList.contains('override-reset')
		){
			event.preventDefault();
			event.stopPropagation();
		}
		
		window.removeEventListener("click", this.onClickCapture, true);

	}
	onFocus = (e)=>{
		this.setState({
			hasFocus: true
		})
	}

	onBlur =(e)=>{

		this.setState({
			showResizingCursor: false,
			hasFocus: false,
		})
		this.onEnter();
	}

	onEnter = () =>{

		const {
			displayValue,
			lastValidValue,
		} = this.state;

		let inputValue = this.processInput(this.scrubberInputRef.current.value);

		if( inputValue.isValid){
			this.setState({
				updateFieldAfterInput: true,
				displayValue: inputValue
			})				
		} else {

			this.setState({
				displayValue: _.cloneDeep(lastValidValue)
			})	
		}
	
	}

}

Scrubber.defaultProps={

	loopValues: false,

	// min, max, step all take in either a single float value or an object eg
	// min={0}
	// min={{
	//	em: -.1,
	//  px: -2,
	//  etc: 0,
	//  noUnit: 0.1
	// }}

	// 'etc' === the default for all non-defined values
	// 'noUnit' === the default for a bare number value without a unit

	// if numberOnlyMode is true, then these values should be a single float eg min={0} max = {10}

	min: undefined,
	max: undefined,
	step: undefined,

	// used when a scrubber is initialized with a bad value but doesn't have a good value to revert back to
	defaultValue: undefined,

	fastPixelsPerDelta: 2,
	slowPixelsPerDelta: 7,

	// number only mode forces the value to unit-less float.
	numberOnlyMode: false, 

	// Aart added this to fix an issue where propWatchers would send the value
	// as string when editing CSS, then the scrubber would coerce to float which
	// in turn updated the CSS again (because formik diff detects string -> float)
	// causing strange jumps and parsing of values being typed (like parsing "0." when typing "0.1")	
	// This scenario happens when using numberOnlyMode in conjuction with propwatchers (eg. setting a var() in CSS)	
	coerceNumberToString: false,

	// IMPORTANT: if numberOnlyMode is active, all of the following properties are not applicable
	allowCalc: false,
	allowVar: false,
	defaultUnit: undefined,	

	// if a default unit is defined, this property will ensure that unitless values are stored in the field as a number with a unit
	// additionally, this strips the unit from the display value (5.2rem -> 5.2)
	addDefaultUnitToUnitlessNumber: false,

	// the scrubber uses the style attribute on the validatorTest element to check that the css value created by the scrubber is valid
	// 'width' is suitable for most cases but special cases may require alternate css properties (like line-height)
	cssTestProperty: 'width',

	// todo:for instances where the text field can take in values like "normal" (letter-spacing) or "auto", 
	// create whitelists of these values
	// currently unused/unsupported

	allowedUnits: ['%', 'cm', 'mm', 'Q', 'in', 'pc', 'pt', 'px', 'em', 'ex', 'ch', 'rem', 'lh', 'vw', 'vh', 'vmin', 'vmax', 'fr']


}

export {Scrubber}

