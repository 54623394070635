import React from 'react';

import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { MoreButton } from "../ui-kit";
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import { wsProvider } from '../../lib/multi-user';


import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";

const TypographyMenuContextUI = (props) => {

	const limitedTags = [
		'bodycopy',
		'h1',
		'h2',
		'.caption'
	];

	return (
		<AlertContext.Consumer>
		    {(Alert) => (
		    	<>
		    	<ContextMenuButton 
			    		label="New Style" 
			    		onPointerUp = { e => { 
		            		props.handleNew();
			    		}}
			    	/>
					<hr/>
			    	<ContextMenuButton 
			    		label="Duplicate"
			    		onPointerUp = { e => { 
		            		props.handleDuplicate();
			    		}}
			    	/>
                    <ContextMenuButton
					label={"Rename"} 
					disabled={limitedTags.indexOf(props.currentTextStyle.tag) > -1}
					onPointerUp={
						(e) => {
		            		Alert.openModal({
			            		header: 'Rename',
			            		type: 'dialog',
			            		placeholder: 'Rename Text Style...',
			            		existingValue: props.currentTextStyle.name,
			            		focusInput: true,
			            		ignoreUnmount: true,
								className: 'rename-text-style',
			            		maxInputLength: 100,
			            		onConfirm: ( newName ) => {
			            			if( newName ){
			            				props.renameCurrentTextStyle(newName);
			            			}
		            			}
		            		})


						}
				}></ContextMenuButton>
				<ContextMenuButton 
			    		label="Remove"
						disabled={limitedTags.indexOf(props.currentTextStyle.tag) > -1}
			    		onPointerUp = { e => { 
		            		props.handleDelete();
			    		}}
			    	/>
		    	</>
		    )}
		</AlertContext.Consumer>
	);

};

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
	}, dispatch);

}

export default connect(
	null,
	mapDispatchToProps
)( TypographyMenuContextUI );
