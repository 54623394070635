import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";

import { MenuContext, ContextMenuButton } from '@cargo/common/context-menu'
import { Alert, AlertContext, Button, Message, MessageContext } from "@cargo/ui-kit";
import { PropigateCustomFontFetch } from "./helpers";

import getGlobalCSSParser from '../../lib/shared-css/get-global-parser';
import { getTextStyleClassNames } from '../../lib/inline-editor/helpers';
import { FRONTEND_DATA } from '../../globals';

import _, { remove } from 'lodash';

class CustomFontEditorButton extends Component {

    constructor(props){
        super(props);

        this.state = {
            hovered: false,
            menuOpen: false,
            ref: React.createRef(),
        }
    }

    setupListeners = () => {
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('pointerdown', this.handlePointerDown);
    }

    removeListeners = () => {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('pointerdown', this.handlePointerDown);
    }

    handleMouseMove = (e) => {
        if (this.state.ref.current && this.state.ref.current.closest('li') && e.target.closest('li') && e.target.closest('li') === this.state.ref.current.closest('li')) {
            this.setState({
                hovered: true,
            });
        } else {
            this.setState({
                hovered: false,
            });
        }
    }

    handlePointerDown = (e) => {
        if (e.target !== this.state.ref.current) {
            this.setState({
                hovered: false,
                menuOpen: false,
            });
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.removeListeners();
        //this.setupListeners();
    }

    componentDidMount() {
        this.setupListeners();
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    getRemovalMessage = () => {
        let associatedFonts = [];
        for (const customFont of this.props.fontCollection.collectionForRender.custom) {
            if (this.props.font.embed.location.includes('https://') && customFont.embed.location === this.props.font.embed.location && customFont.family_name !== this.props.font.family_name) {
                associatedFonts.push(customFont);
            }
            if (this.props.font.embed.location.includes('https://') && this.props.font.embed.location === customFont.embed.location && customFont.family_name === this.props.font.family_name && customFont.variants.length > 1) {
                associatedFonts.push(customFont);
            }
        }
        if (associatedFonts.length ) {
            //return `Removing this font will also remove: ${associatedFonts.map(font => font.family_name).join(', ')}.`;
            return 'This will remove multiple fonts.';
        } else {
            return '';
        }
    }

    addEmbedCodeToCRDT = async (embedCode, shouldPropigate) => {
        switch(true) {
          case embedCode.startsWith('@import'): {
            getGlobalCSSParser().mutateSharedType(stylesheet => {
              stylesheet.insert(0, embedCode + '\n');
            });
            break;
          }
          case embedCode.startsWith('@font-face'): {
            getGlobalCSSParser().mutateSharedType(stylesheet => {
              const stylesheetString = stylesheet.toString();
              const index = stylesheetString.includes('@import') ? stylesheetString.indexOf(';', stylesheetString.lastIndexOf('@import')) + 1 : 0;
              stylesheet.insert(index, index > 1 && stylesheet[index] !== '\n' ? `\n\n${embedCode}` : `${embedCode}`);
            });
            break;
          }
          case embedCode.startsWith('<style>'):
          case embedCode.startsWith('<link'): {
            const { CRDTItem: siteCRDTItem } = getCRDTItem({ reducer: 'site' });      
            let customHTML = siteCRDTItem.get('custom_html');
            // Prepend the embed code to the custom html
            customHTML = embedCode + '\n' + customHTML;
            siteCRDTItem.set('custom_html', customHTML);
            break;
          }
          default: {
            console.log('Could not identify where to place embed code.');
          }
        }
    
        if (shouldPropigate) {
            PropigateCustomFontFetch();
        }
    
    }

    updateEmbedCodeInCRDT = (embedCode) => {
        const startsWithLocations = [
            {
                location: 'css.style',
                startsWith: '@font-face'
            },
            {
                location: 'css.import',
                startsWith: '@import'
            },
            {
                location: 'html.import',
                startsWith: '<style>'
            },
            {
                location: 'html.link',
                startsWith: '<link'
            }
        ];
        const previousLocation = startsWithLocations.find((pair) => this.props.font.embed.location.startsWith(pair.location)).location;
        const newLocation = startsWithLocations.find((pair) => embedCode.startsWith(pair.startsWith)).location;
        // console.log(previousLocation, newLocation, this.props.font.embed.location, embedCode)
        const isEditInPlace = previousLocation === newLocation;

        if (!isEditInPlace) {
            // First, remove the old embed code
            this.removeFontFromCRDT(false);
            // Then, insert the new embed code
            this.addEmbedCodeToCRDT(embedCode, false);
        } else {
            switch (true) {
                case embedCode.startsWith('@import'): {
                    const url = this.props.font.embed.location.split(`['`)[1].split(`']`)[0];
                    getGlobalCSSParser().mutateSharedType(stylesheet => {
                        const stylesheetString = stylesheet.toString();
                        // Find the index of the previous URL
                        const urlIndex = stylesheetString.indexOf(url);
                        // Find the index of the previous @import
                        const importIndex = stylesheetString.lastIndexOf('@import', urlIndex);
                        // Find the index of the semicolon after the previous @import
                        const semicolonIndex = stylesheetString.indexOf(';', importIndex);
                        // Delete the previous @import
                        stylesheet.delete(importIndex, semicolonIndex - importIndex + 1);
                        // Insert the new @import
                        stylesheet.insert(importIndex, embedCode);
                    });
                    break;
                }
                case embedCode.startsWith('@font-face'): {
                    const prevRule = this.props.font.embed.rule;
                    const { CRDTItem: cssCRDTItem } = getCRDTItem({ reducer: 'css' });
                    getGlobalCSSParser().mutateSharedType(stylesheet => {
                        const stylesheetString = stylesheet.toString();
                        const index = stylesheetString.indexOf(prevRule);
                        ydoc.transact(() => {
                            stylesheet.delete(index, index + prevRule.length);
                            stylesheet.insert(index, index > 1 ? `\n\n${embedCode}\n\n` : `${embedCode}\n\n`);
                        });
                    });
                    break;
                }
                case embedCode.startsWith('<style>'): {
                    const { CRDTItem: siteCRDTItem } = getCRDTItem({ reducer: 'site' });
                    let customHTML = siteCRDTItem.get('custom_html');
                    const url = this.props.font.embed.location.split(`['`)[1].split(`']`)[0];
                    const urlIndex = customHTML.indexOf(url);
                    const styleTagStartIndex = customHTML.lastIndexOf('<style', urlIndex);
                    const styleTagEndIndex = customHTML.indexOf('/style>', styleTagStartIndex) + 7;
                    customHTML = customHTML.replace(customHTML.substring(styleTagStartIndex, styleTagEndIndex), embedCode);
                    siteCRDTItem.set('custom_html', customHTML);
                    break;
                }
                case embedCode.startsWith('<link'): {
                    const { CRDTItem: siteCRDTItem } = getCRDTItem({ reducer: 'site' });      
                    let customHTML = siteCRDTItem.get('custom_html');
                    const url = this.props.font.embed.location.split(`['`)[1].split(`']`)[0];
                    const urlIndex = customHTML.indexOf(url);
                    const startLinkIndex = customHTML.lastIndexOf('<link', urlIndex);
                    const endLinkIndex = customHTML.indexOf('>', startLinkIndex);
                    customHTML = customHTML.replace(customHTML.substring(startLinkIndex, endLinkIndex + 1), embedCode);
                    siteCRDTItem.set('custom_html', customHTML);
                    break;
                }
                default: {
                    console.log('Could not identify where to place embed code.', embedCode);
                }
            }
        }
        // this.props.fetchFontCollection(true);
        // this.props.fetchCustomFontCollection( this.props.fontCollection.unprocessedCollection );
        PropigateCustomFontFetch();
    }

    removeFontReferencesFromCRDT = ({fontFamily, fontWeight, fontStyle}) => {
        const parsedRules = getGlobalCSSParser().getParsedRules();

        const textStyleClassNames = getTextStyleClassNames();
        const selectors = ['bodycopy', 'h1', 'h2', ...textStyleClassNames];

        for (const selector of selectors) {
            const propWatchers = getGlobalCSSParser().getPropWatchers([
                {
                    selector,
                    property: 'font-family'
                },
                {
                    selector,
                    property: 'font-style'
                },
                {
                    selector,
                    property: 'font-weight'
                },
                {
                    selector,
                    property: 'font-variation-settings'
                }
            ]);

            let hasFamily = false;
            let hasStyle = (fontStyle === 'normal' && !propWatchers.find(propWatcher => propWatcher.propertyName === 'font-style'));
            let hasWeight = (fontWeight === 400 && !propWatchers.find(propWatcher => propWatcher.propertyName === 'font-weight'));

            for (const propWatcher of propWatchers) {
                if (
                    propWatcher.propertyName === 'font-family' &&
                    propWatcher.value === fontFamily
                ) {
                    hasFamily = true;
                }

                if (
                    propWatcher.propertyName === 'font-style' &&
                    (
                        propWatcher.value === fontStyle ||
                        (fontStyle === 'normal' && propWatcher.value === undefined)
                    )
                ) {
                    hasStyle = true;
                }

                if (
                    propWatcher.propertyName === 'font-weight' &&
                    (
                        propWatcher.value === fontWeight ||
                        (propWatcher.value === 400 && propWatcher.value === undefined)
                    )
                ) {
                    hasWeight = true;
                }
            }

            const isVariable = this.props.font.variable;

            if (hasFamily && hasStyle && hasWeight || isVariable) {
                for (const propWatcher of propWatchers) {
                    switch (propWatcher.propertyName) {
                        case 'font-family':
                            propWatcher.setValue('"Diatype Variable"');
                            break;
                        case 'font-style':
                            propWatcher.setValue("normal");
                            break;
                        case 'font-weight':
                            propWatcher.setValue(400);
                            break;
                        case 'font-variation-settings':
                            propWatcher.setValue("'slnt' 0, 'MONO' 0");
                            break;
                    }
                }
            }
        }
    }

    encodeEntities = (decodedString) => {
        var textArea = document.createElement('textarea');
        textArea.innerText = decodedString;
        return textArea.innerHTML;
    }

    removeFontFromCRDT = (shouldPropigate = true) => {
        const embed = this.props.font.embed;
        switch (true) {
            case this.props.font.embed.location.startsWith('css.style'): {

                const fontFamily = this.props.font.css_font_family;
                const fontStyle = this.props.font.css_font_style;
                const fontWeight = this.props.font.css_font_weight;

                getGlobalCSSParser().mutateSharedType(stylesheet => {
                    const stylesheetString = stylesheet.toString();
                    const ruleIndex = stylesheetString.indexOf(this.props.font.embed.rule);
                    const lastRuleIndex = stylesheetString.lastIndexOf('}', ruleIndex) + 1;

                    const ruleLength = this.props.font.embed.rule.length;
                    const lengthBetweenRules = stylesheetString.substring(lastRuleIndex, ruleIndex).length + 1;

                    ydoc.transact(() => {
                        stylesheet.delete(lastRuleIndex, ruleLength + lengthBetweenRules);
                    });
                });

                this.removeFontReferencesFromCRDT({fontFamily, fontWeight, fontStyle});
                break;
            }
            case this.props.font.embed.location.startsWith('css.import'):
                const url = embed.location.split(`['`)[1].split(`']`)[0];
                const encodedUrl = this.encodeEntities(url);
                const fontsWithSameURL = this.props.fontCollection.collectionForRender.custom.filter(font => font.embed.location.includes(url)).map(font => font.variants).flat();
                getGlobalCSSParser().mutateSharedType(stylesheet => {
                    const stylesheetString = stylesheet.toString();
                    const urlIndex = stylesheetString.indexOf(url) !== -1 ? stylesheetString.indexOf(url) : stylesheetString.indexOf(encodedUrl);
                    if (urlIndex === -1) {
                        console.error('Could not find url in stylesheet');
                        return;
                    }
                    const importIndex = stylesheetString.lastIndexOf('@import', urlIndex);
                    const semicolonIndex = stylesheetString.indexOf(';', importIndex);
                    stylesheet.delete(importIndex, semicolonIndex - importIndex + 1);
                });
                for (const font of fontsWithSameURL) {
                    this.removeFontReferencesFromCRDT({fontFamily: font.css_font_family, fontWeight: font.css_font_weight, fontStyle: font.css_font_style});
                }
                break;
            case this.props.font.embed.location.startsWith('html.style'): {
                const { CRDTItem: siteCRDTItem } = getCRDTItem({ reducer: 'site' });      
                let customHTML = siteCRDTItem.get('custom_html');
                customHTML = customHTML.replace(embed.rule, '')
                // TO DO: remove empty style tags
                siteCRDTItem.set('custom_html', customHTML);

                const fontFamily = this.props.font.css_font_family;
                const fontStyle = this.props.font.css_font_style;
                const fontWeight = this.props.font.css_font_weight;

                this.removeFontReferencesFromCRDT({fontFamily, fontWeight, fontStyle});
                break;
            }
            case this.props.font.embed.location.startsWith('html.link'): {
                const url = embed.location.split(`['`)[1].split(`']`)[0];
                const encodedUrl = this.encodeEntities(url);
                const { CRDTItem: siteCRDTItem } = getCRDTItem({ reducer: 'site' });      
                let customHTML = siteCRDTItem.get('custom_html');
                const urlIndex = customHTML.indexOf(url) !== -1 ? customHTML.indexOf(url) : customHTML.indexOf(encodedUrl);
                if (urlIndex === -1) {
                    console.error('Could not find url in stylesheet');
                    return;
                }
                const startLinkIndex = customHTML.lastIndexOf('<link', urlIndex);
                const endLinkIndex = customHTML.indexOf('>', startLinkIndex);
                // Remove the link tag from the HTML
                customHTML = customHTML.replace(customHTML.substring(startLinkIndex, endLinkIndex + 1), '');
                siteCRDTItem.set('custom_html', customHTML);

                const fontsWithSameURL = this.props.fontCollection.collectionForRender.custom.filter(font => font.embed.location.includes(url)).map(font => font.variants).flat();
                for (const font of fontsWithSameURL) {
                    this.removeFontReferencesFromCRDT({fontFamily: font.css_font_family, fontWeight: font.css_font_weight, fontStyle: font.css_font_style});
                }
                break;
            }
            case this.props.font.embed.location.startsWith('html.import'): {
                const url = embed.location.split(`['`)[1].split(`']`)[0];
                const encodedUrl = this.encodeEntities(url);
                const { CRDTItem: siteCRDTItem } = getCRDTItem({ reducer: 'site' });
                let customHTML = siteCRDTItem.get('custom_html');
                const urlIndex = customHTML.indexOf(url) !== -1 ? customHTML.indexOf(url) : customHTML.indexOf(encodedUrl);
                if (urlIndex === -1) {
                    console.error('Could not find url in stylesheet');
                    return;
                }
                const styleTagStartIndex = customHTML.lastIndexOf('<style', urlIndex);
                const styleTagEndIndex = customHTML.indexOf('/style>', styleTagStartIndex) + 7;
                customHTML = customHTML.replace(customHTML.substring(styleTagStartIndex, styleTagEndIndex), '');
                siteCRDTItem.set('custom_html', customHTML);

                const fontsWithSameURL = this.props.fontCollection.collectionForRender.custom.filter(font => font.embed.location.includes(url)).map(font => font.variants).flat();
                for (const font of fontsWithSameURL) {
                    this.removeFontReferencesFromCRDT({fontFamily: font.css_font_family, fontWeight: font.css_font_weight, fontStyle: font.css_font_style});
                }
                break;
            }
            default: 
                console.log('Could not remove font from CRDT');
        }
        // this.props.fetchFontCollection(true);
        // this.props.fetchCustomFontCollection( this.props.fontCollection.unprocessedCollection );
        if (shouldPropigate) {
            PropigateCustomFontFetch();
        }
    }

    render(){

        const embed = this.props.font.embed;

        const decodeEntities = (encodedString) => {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = encodedString;
            return textArea.value;
        }

        if( !embed ){
            return null;
        }

        return <MenuContext.Consumer>
            {(Menu) => { return (
                <span
                    ref={this.state.ref}
                    className={`edit-custom-group ${this.props.single? 'single' : ''} ${this.props.font.variable ? 'variable': ''} ${this.state.hovered || this.state.menuOpen ? 'visible' : ''}`}
                    // onPointerEnter={(e)=>{
                    //     this.props.onCustomHover?.(false)
                    //     this.setState({
                    //         hovered: true,
                    //     })                
                    // }}
                    // onPointerLeave={(e)=>{
                    //     this.props.onCustomHover?.(false);
                    //     this.setState({
                    //         hovered: false,
                    //     })
                    // }}
                    onMouseDown={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();

                        this.setState({
                            menuOpen: true,
                        })
                        Menu.openMenu({
                            type: 'button',
                            event: e,
                            offset: {x: 10, y: -12},
                            innerUI: <>
                                
                                <ContextMenuButton 
                                    label="Edit"
                                    onPointerUp = { e => {
                                        this.setState({
                                            menuOpen: false,
                                        })
                                        // const buttonPos =  document.querySelector('edit-custom-group[style*="opacity: 1;"]').getBoundingClientRect();
                                        const buttonPos = this.state.ref.current.getBoundingClientRect();
                                        buttonPos.x = buttonPos.x - 310;
                                        buttonPos.y = buttonPos.y + 100;

                                        if (this.props.font.embed.location.startsWith('css') && !this.props.font.embed.location.includes('https://')) {

                                        const defaultCSSString = this.props.font.embed.rule;
                                            this.props.addUIWindow({
                                                group: 'code',
                                                component: import('../right-menu-bar/css-font-editor-window'),
                                                id: 'css-font-editor-window',
                                                props: {
                                                    windowName: 'css-font-editor-window',
                                                    type: 'code-popover',
                                                    positionType: 'from-button',
                                                    autoHeight: true,
                                                    buttonPos: {
                                                        y: buttonPos.y,
                                                        x: buttonPos.x,
                                                        left: buttonPos.x,
                                                        bottom: buttonPos.y,
                                                        top: buttonPos.y,
                                                        right: buttonPos.x,
                                                        height: 0,
                                                        width: 0,
                                                    },
                                                    cssString: defaultCSSString,
                                                    allowScroll: true,
                                                    closeButton: false,
                                                    onClose: (val) => {
                                                        if (val !== defaultCSSString) {
                                                            this.updateEmbedCodeInCRDT(val);
                                                        }
                                                    },
                                                    scope: this.props.type, // global, local, etc...
                                                    closeOnSingleClickout: true,
                                                    invokeTarget: e.currentTarget,
                                                    invokeWindow: 'custom-font-wizard'
                                                } 
                                            });
                                        } else {
                                            if (this.props.font.embed.location.startsWith('css')) {

                                                // const buttonPos = document.querySelector('div[window-name="font-picker"]').getBoundingClientRect();
                                                const url = embed.location.split(`['`)[1].split(`']`)[0];
                                                const defaultCSSString = `@import url('${url}');`

                                                this.props.addUIWindow({
                                                    group: 'code',
                                                    component: import('../right-menu-bar/css-font-editor-window'),
                                                    id: 'css-font-editor-window',
                                                    props: {
                                                        windowName: 'css-font-editor-window',
                                                        type: 'code-popover',
                                                        positionType: 'over-button',
                                                        autoHeight: true,
                                                        buttonPos: {
                                                            y: buttonPos.y,
                                                            x: buttonPos.x,
                                                            left: buttonPos.x,
                                                            bottom: buttonPos.y,
                                                            top: buttonPos.y,
                                                            right: buttonPos.x,
                                                            height: 0,
                                                            width: 0,
                                                        }, 
                                                        cssString: defaultCSSString,
                                                        allowScroll: true,
                                                        closeButton: false,
                                                        onClose: (val) => {
                                                            if (val !== defaultCSSString) {
                                                                this.updateEmbedCodeInCRDT(val);
                                                            }
                                                        },
                                                        scope: this.props.type, // global, local, etc...
                                                        closeOnSingleClickout: true,
                                                        invokeTarget: e.currentTarget,
                                                        invokeWindow: 'custom-font-wizard'
                                                    } 
                                                });
                                            } else {
                                                let node = null;
                                                switch (true) {
                                                    case this.props.font.embed.location.startsWith('html.import'): {
                                                        // Find the style tag containing an import statement with the url
                                                        const url = embed.location.split(`['`)[1].split(`']`)[0];
                                                        const styleNodes = FRONTEND_DATA.contentWindow.document.querySelectorAll('style');
                                                        for (const styleNode of styleNodes) {
                                                            const decodedOuterHTML = decodeEntities(styleNode.outerHTML);
                                                            if (decodedOuterHTML.includes(url)) {
                                                                node = styleNode;
                                                                break;
                                                            }
                                                        }
                                                        break;
                                                    }
                                                    case this.props.font.embed.location.startsWith('html.link'): {
                                                        // Find the link tag containing the url
                                                        const url = embed.location.split(`['`)[1].split(`']`)[0];
                                                        const linkNodes = FRONTEND_DATA.contentWindow.document.querySelectorAll('link');
                                                        for (const linkNode of linkNodes) {
                                                            const decodedOuterHTML = decodeEntities(linkNode.outerHTML);
                                                            if (decodedOuterHTML.includes(url)) {
                                                                node = linkNode;
                                                                break;
                                                            }
                                                        }
                                                        break;
                                                    }
                                                }

                                                if (!node) {
                                                    console.error('no node')
                                                    return;
                                                }

                                                this.props.addUIWindow({
                                                    group: 'code',
                                                    component: import('../right-menu-bar/html-tag-window'),
                                                    id: 'html-tag-window',
                                                    props: {
                                                    windowName: 'html-tag-window',
                                                    type: 'code-popover',
                                                    positionType: 'over-button',
                                                    autoHeight: true,
                                                    onClose: (val) => {
                                                        if (val !== node.outerHTML) {
                                                            this.updateEmbedCodeInCRDT(val);
                                                        }
                                                    },
                                                    buttonPos: {
                                                        y: buttonPos.y,
                                                        x: buttonPos.x,
                                                        left: buttonPos.x,
                                                        bottom: buttonPos.y,
                                                        top: buttonPos.y,
                                                        right: buttonPos.x,
                                                        height: 0,
                                                        width: 0,
                                                    }, 
                                                    allowScroll: true,
                                                    clickoutLayer: true,
                                                    disableDragging: true,
                                                    closeOnSingleClickout: true,
                                                    invokeTarget: e.currentTarget,
                                                    invokeWindow: 'custom-font-wizard',
                                                    htmlNode: node
                                                    }
                                                });
                                            }
                                        }

                                    }}
                                />
                                <hr/>
                                
                                <AlertContext.Consumer>
                                    {(Alert) => (
                                        
                                        <ContextMenuButton 
                                            label="Remove"
                                            onPointerUp = { e => {
                                                this.setState({
                                                    menuOpen: false,
                                                })

                                                const removeMessage = this.getRemovalMessage();
                                                if ( removeMessage.length > 0 ) {
                                                    Alert.openModal({
                                                        header: removeMessage, 
                                                        type: 'confirm',
                                                        onConfirm: (options) => {
                                                            this.removeFontFromCRDT();
                                                        },
                                                        onClose: () => {}
                                                    })
                                                } else {
                                                    this.removeFontFromCRDT();
                                                }

                                            }}
                                        />

                                    )}
                                </AlertContext.Consumer>    

                            </>,
                        })
                    }}                            
                >
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle className="inner-circle" cx="8.5" cy="8.65747" r="7.5" />
                        <circle className="outer-circle" cx="8.5" cy="8.65747" r="8" />
                        <circle className="dot" cx="8.49977" cy="8.65747" r="1" />
                        <circle className="dot" cx="5.07" cy="8.65747" r="1" />
                        <circle className="dot" cx="11.9295" cy="8.65747" r="1" />
                    </svg>                            
                </span> 
            )}}
        </MenuContext.Consumer>
    }
}



function mapReduxStateToProps(state, ownProps) {

    return {
        unprocessedFontsLinkedByEmbedCode: state.fontCollection.unprocessedCollection.filter(font=>font.embeddedFontReference === ownProps.font.embeddedFontReference),
        fontsLinkedByEmbedCode: state.fontCollection.collectionForRender.custom.filter(font=>font.embeddedFontReference === ownProps.font.embeddedFontReference),
        fontsLoaded: state.frontendState.fontsLoaded,
        fontCollection: state.fontCollection,    
    };

}

function mapDispatchToProps(dispatch) {
    
    return bindActionCreators({
        fetchFontCollection:actions.fetchFontCollection,
        fetchCustomFontCollection: actions.fetchCustomFontCollection,
        addUIWindow: actions.addUIWindow,       
        removeUIWindow: actions.removeUIWindow,
        updateFrontendState: actions.updateFrontendState,
    }, dispatch);

}

export default connect(
    mapReduxStateToProps, 
    mapDispatchToProps
)(CustomFontEditorButton);