import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Alert, AlertContext } from "@cargo/ui-kit";
import { helpers } from "@cargo/common";

import Thumbnail from './thumbnail';
import { actions } from '../../actions';
import selectors from '../../selectors';
import { convertStateToSharedType } from "../../lib/multi-user/redux";
import globalDragEventController from "../drag-event-controller";
import { getSupportedFileTypes } from './helpers';
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, useSortable, arrayMove } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { uploadMedia, deleteMedia, convertFileToImage } from '../../lib/media';
import { CRDTState } from '../../globals';
import UploadButton from './upload-button';
import LibraryButton from './library-button';
import FilesButton from './files-button';
import UploadProgressIndicator from './upload-progress-indicator';
import { on } from 'ws';

const FilePicker = ({
    label = null,
    files = [], 
    emptyMessage = 'Drop a file here',
    columns = 1,
    componentWidth = 310,
    allowDragOut = false,
    addUIWindow = null,
    removeUIWindow = null,
    showFilesButton = true,
    clickoutLayer = false,
    showUploadButton = true,
    showButtons = true,
    renderAsWindow = false,
    allowDuplicates = false,
    onDrop = null,
    onUpload = null,
    globalFiles = [],
    allowedFileTypes = getSupportedFileTypes(),
    disabled = false,
    invalidFileMessage = null

}) => {
    const [dragging, setDragging] = useState(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [models, setModels] = useState([]);

    useEffect(() => {
        const newModels = [];
        for (const file of files) {
            const model = globalFiles.find((i) => i.hash === file.hash);
            if (model) {
                newModels.push(model);
            }
        }
        setModels(newModels);
    }, [files, globalFiles])

    useEffect(() => {
        globalDragEventController.on('dragover', handleDragOver);
        globalDragEventController.on('drop', handleDrop);

        return function cleanup () {
            globalDragEventController.off('dragover', handleDragOver);
            globalDragEventController.off('drop', handleDrop);
        }
    }, [isDraggingOver])

    useEffect(() => {
    }, [disabled])

    const formatBytes = (bytes, decimals = 2) => {
	    if (bytes === 0) return '0 Bytes';

	    const k = 1024;
	    const dm = decimals < 0 ? 0 : decimals;
	    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	    const i = Math.floor(Math.log(bytes) / Math.log(k));

	    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}	

    const handleDragOver = (editor, e, dragData) => {
        if ( onDrop === null ) {
            return;
        }
		let draggingOver = false;
		const toElement = e.toElement;
		if ( filepickerRef.current && ( filepickerRef.current.contains(toElement) || filepickerRef.current === toElement ) ) {
			draggingOver = true;
		}
        setIsDraggingOver(draggingOver);
	}

    const uploadFiles = async (files) => {
        const newModels = [];
        for ( const file of files ) {
            file.onHash = hash => {
                const fullURL = `https://freight.cargo.site/m/${hash}/${file.name}`
                const model = {
                    hash,
                    name: file.name,
                    fullURL,
                    loading: true,
                }
                onDrop([model]);
            }
            try {
                const uploadedFile = await uploadMedia({
                    target: getCRDTItem({ reducer: 'media' }),
                    field: 'data'
                }, [file])[0]
                uploadedFile.model.fullURL = `https://freight.cargo.site/m/${uploadedFile.model.hash}/${uploadedFile.model.name}`
                newModels.push(uploadedFile.model);
            } catch (err) {
                console.error(err);
            }
        }
        onDrop(newModels);
	}

    const handleDrop = async (editor, e, dragData) => {
        if (onDrop === null || isDraggingOver === false || !filepickerRef.current.contains(e.target)) {
            return;
        }
		if (dragData.inAdmin && dragData.fromOutside) {
			const droppedFiles = dragData.dataTransfer.get('files');
            const filteredFiles = droppedFiles.filter((file) => {
                return (
                    allowedFileTypes.includes(file.type) ||
                    // If file extension matches allowed file types endings (after the /)
                    allowedFileTypes.some((i) => file.name.endsWith(i.split('/')[1]))
                )
            })
            if (filteredFiles.length === 0 && invalidFileMessage ) {
                document.dispatchEvent(new CustomEvent('open-remote-alert', {
                    detail: {
                        message: invalidFileMessage
                    }
                }));
            }
            uploadFiles(filteredFiles);
		}
		if ( dragData.fromAdmin === true && dragData.inAdmin === true && (e.srcElement.contains(filepickerRef.current) || filepickerRef.current.contains(e.srcElement)) ) {
			dragData.draggedNodes.forEach((node) => {
                const hash = node.getAttribute('hash');
                const model = globalFiles.find((i) => i.hash === hash);
                if(onDrop){
                    if (allowedFileTypes.includes(model.mime_type)) {
                        onDrop([model]);
                    }
                }
			})				
		}
        setIsDraggingOver(false);
	}

    const filepickerRef = useRef();

    return (
        <div className={`file-picker ui-group${isDraggingOver ? ' draggingover' : ''}`} ref={filepickerRef} style={disabled === true ? {pointerEvents: 'none', opacity: 0.5} : null}>
            {showButtons === true ? (
                <div className="button-row grid-columns-even">
                    {showUploadButton && (
                        <div>
                            <UploadButton
                                onHash={(hash, file) => {
                                    const fullURL = `https://freight.cargo.site/m/${hash}/${file.name}`
                                    const model = {
                                        hash,
                                        name: file.name,
                                        fullURL,
                                        loading: true,
                                    }
                                    onDrop([model]);
                                }}
                                onFileUpload={onDrop ? (val) => {
                                    if (onDrop) {
                                        val.model.fullURL = `https://freight.cargo.site/m/${val.model.hash}/${val.model.name}`
                                        onDrop([val.model]);
                                    }
                                } : null}
                                suppressFileWindow={true}
                                allowedExtensions={allowedFileTypes.map((i) => i.split('/')[1])}
                            />
                        </div>
                    )}
                    {showFilesButton && (
                        <div>
                            <FilesButton/>
                        </div>
                    )}
                </div>
            ) : null}
            {models.length > 0 ? (
                <div 
                    className="image-picker"
                    style={{
                        gridTemplateColumns: columns ? new Array(columns).fill('1fr').join(' ') :''
                    }}

                >
                    {models.map((model, index) => {
                        const loading = model.loading;
                        return loading ? (
                            <div
                                className={`file`} 
                                draggable={false}
                                key={model.hash} 
                            >
                                <div className="type">
                                    <UploadProgressIndicator hash={file.hash} type="file" />
                                    {/* <span>{item.file_type?.toUpperCase()}</span> */}
                                </div>
                                <div className="name">Uploading...</div>
                                <div className="size"><UploadProgressIndicator numerical={true} hash={file.hash} />%</div>
                            </div>
                        ) : (
                            <div key={model.hash} className="file"><div className="type"><span>{model.file_type}</span></div><div className="name">{model.name}</div><div className="size">{formatBytes(model.file_size)}</div></div>
                        )
                    })}
                </div>
            ) : (
                <div className="image-picker image-picker--empty">
                    <div className="image-picker__icon">
                        <svg width="59" height="74" viewBox="0 0 59 74" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M43.5 15.5V1H1V73H58V15.5H43.5ZM59 14.5V74H0V0H44.5L59 14.5ZM44.5 1.41421L57.5858 14.5H44.5V1.41421Z" fill="#CECECE"/>
						</svg> 
                    </div>
                    <div className="image-picker__message">
                        <span>{emptyMessage}</span>
                    </div>
                </div>
            )}
        </div>
    )}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addUIWindow: actions.addUIWindow,
        updateUIWindow: actions.updateUIWindow,
        removeUIWindow: actions.removeUIWindow,
    }, dispatch);

}

function mapReduxStateToProps(state, ownProps) {

	let globalFiles = state.media.data.filter(item => !item.is_image && item.crdt_state !== CRDTState.Deleted) 
	if( ownProps.filter){
		globalFiles = globalFiles.filter(ownProps.filter);
	}
	return {
        globalFiles
	};
}
    
export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(FilePicker);

//export {FilePicker};